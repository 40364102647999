.op-app .op-content-body {
  position: relative;
  top: 40px;
  left: 60px;
  width: calc(100% - 60px);
  height: calc(100vh - 40px);
  transition: 0.5s;
}

.op-app .op-content-body.active {
  left: 300px;
  width: calc(100% - 300px);
}

.op-app .op-content-body .op-content-component {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #e7eaec;
  overflow: auto;
}

.op-app .op-content-body .op-content-component.op-close {
  display: none;
}

.op-app .op-content-body .op-content-component .op-component {
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  height: calc(100% - 10px);
}

@media (max-width: 767px) {
  .op-app .op-content-body {
    left: 0;
    width: 100%;
  }
}
