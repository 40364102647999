.op-content-diagram .op-bloque-content {
  overflow: auto;
  height: calc(100% - 40px);
}

.op-content-diagram .op-content-task {
  position: absolute;
  width: 100px;
  height: 60px;
  border: 1px solid rosybrown;
  border-radius: 10px;
  background-color: skyblue;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.op-content-diagram .op-content-task.start {
  border-radius: 100%;
  border: 3px solid greenyellow;
  background-color: khaki;
  width: 40px;
  height: 40px;
}

.op-content-diagram .op-content-task .actions {
  position: absolute;
  right: 0;
  top: -5px;
  width: 30px;
  display: flex;
  flex-wrap: wrap;
  z-index: -1;
  opacity: 0;
  background-color: #ffffff;
  transition: 0.2s all ease-in;
}

.op-content-diagram .op-content-task .actions.show {
  z-index: 10;
  right: -35px;
  opacity: 1;
}

.op-content-diagram .op-content-task .actions .new-task {
  position: relative;
  width: 13px;
  height: 15px;
  border: 1px solid rosybrown;
  border-radius: 3px;
  background-color: skyblue;
}

.op-content-diagram .op-content-task input,
.op-content-diagram .op-content-task.edit label {
  display: none;
}

.op-content-diagram .op-content-task.edit input,
.op-content-diagram .op-content-task label {
  display: block;
}

.op-content-diagram .op-content-task input {
  width: calc(100% - 10px);
}

.op-content-diagram .op-content-task label {
  text-align: center;
}

.op-content-diagram .op-content-task .settings {
  position: absolute;
  top: 2px;
  right: 2px;
}

.op-content-diagram .op-conector {
  position: absolute;
}

.op-content-diagram .op-conector label {
  position: absolute;
  left: 25%;
  bottom: -12px;
}

.op-content-diagram .op-conector .actions {
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
  display: flex;
  flex-wrap: wrap;
  z-index: -1;
  opacity: 0;
  transition: 0.2s all ease-in;
}

.op-content-diagram .op-conector .actions.show {
  z-index: 1;
  top: -15px;
  opacity: 1;
}
