.op-content-info {
  position: absolute;
  top: -100%;
  right: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 5px 10px;
  z-index: -1;
  opacity: 0;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  transition-property: opacity, top;
  transition-duration: 0.2s, 0.5s;
}

.op-content-info.open {
  top: 40px;
  z-index: 10;
  opacity: 1;
}

.op-content-info .separador {
  width: 100%;
  margin: 10px 0;
}

.op-content-info .separador.line {
  height: 1px;
  background-color: #cccccc;
}

.op-content-info .op-lista-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
