.op-content-radiobutton {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.op-content-radiobutton .element {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.op-content-radiobutton .op-checkbox-tools {
  display: none;
}

.op-content-radiobutton label {
  position: relative;
  display: inline-block;
  padding: 10px !important;
  top: 0 !important;
  left: 0 !important;
  min-width: 110px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  margin: 0 auto;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
  text-align: center;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  text-transform: uppercase;
  color: #ffffff;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}

.op-content-radiobutton .op-checkbox-tools:not(:checked) + label {
  background-color: #353746;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.op-content-radiobutton .op-checkbox-tools:checked + label {
  background-image: linear-gradient(298deg, #da2c4d, #f8ab37);
  color: #ffffff;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.op-content-radiobutton .op-checkbox-tools:not(:checked) + label {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.op-content-radiobutton .op-checkbox-tools:not(:checked) + label {
  background-color: #f0eff3;
  color: #1f2029;
  box-shadow: 0 1x 4px 0 rgba(0, 0, 0, 0.05);
}
