.view-inicio {
  height: auto !important;
}

.view-inicio .grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(200px, auto);
  grid-template-areas:
    'ingresos reporteria reporteria'
    'top top servicios';
  gap: 20px;
  width: 100%;
}

.view-inicio .grid-container .grid-item {
  display: flex;
  position: relative;
}

.view-inicio .grid-container .grid-item.ingresos {
  grid-area: ingresos;
}

.view-inicio .grid-container .grid-item.reporteria {
  grid-area: reporteria;
}

.view-inicio .grid-container .grid-item.top {
  grid-area: top;
}

.view-inicio .grid-container .grid-item.servicios {
  grid-area: servicios;
}

.view-inicio .grid-container .grid-item.action {
  flex-direction: column;
}

.view-inicio .grid-container .grid-item.action .sync {
  position: absolute;
  top: 3px;
  right: 5px;
  color: #ffffff;
  cursor: pointer;
}

.view-inicio .grid-container .grid-item.action .title {
  font-size: 20px;
  background-color: #394264;
  color: #ffffff;
  display: block;
  width: 100%;
  text-align: center;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.view-inicio .grid-container .grid-item.action .actions {
  background-color: #394264;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 10px 0;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.view-inicio .bar-chart-block {
  height: 380px;
}

.view-inicio .block {
  margin: 25px 25px 0 0;
  background: #394264;
  border-radius: 5px;
  float: left;
  width: 300px;
  overflow: hidden;
}

.view-inicio .titular {
  display: block;
  line-height: 60px;
  margin: 0;
  text-align: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: #ffffff;
}

.view-inicio .actual {
  color: #ffffff;
  font-size: 35px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #394264;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.view-inicio .actual span:last-child {
  font-size: 15px;
}

.view-inicio .bar-filter {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5px 0;
}

.view-inicio .bar-filter span,
.view-inicio .grid-container .grid-item.action .actions > span.tipo {
  display: block;
  width: 70px;
  line-height: 32px;
  background: #50597b;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
  margin-right: 5px;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
  color: #ffffff;
  cursor: pointer;
}

.view-inicio .bar-filter span.tipo:hover,
.view-inicio .grid-container .grid-item.action .actions > span.tipo:hover,
.view-inicio .bar-filter span.activo,
.view-inicio .grid-container .grid-item.action .actions > span.tipo.activo {
  background: #e64c65;
}

.view-inicio .grafico.bar-chart {
  background: #3468af;
  padding: 0 1rem 2rem 1rem;
  width: 100%;
  height: 50%;
  position: relative;
  color: #fff;
  font-size: 80%;
}

.view-inicio .bar-chart .eje {
  position: absolute;
  left: 0;
  bottom: 0rem;
  width: 100%;
  padding: 1rem 1rem 0 2rem;
  height: 80%;
  padding: 0 1rem 0 2rem;
  bottom: 1rem;
  list-style-type: none;
}

.view-inicio .bar-chart .eje-x {
  bottom: 0;
  padding: 0 1rem 0 2rem;
  margin: 0;
  height: 2.5rem;
}

.view-inicio .bar-chart .eje-y li {
  height: 20%;
  border-top: 1px solid #fff;
}

.view-inicio .bar-chart .eje-x li {
  width: 14%;
  position: relative;
  text-align: left;
  float: left;
}

.view-inicio .bar-chart .eje-y li::before {
  content: attr(data-ejey);
  display: inline-block;
  width: 2rem;
  text-align: right;
  line-height: 0;
  position: relative;
  left: -2.5rem;
  top: -0.5rem;
}

.view-inicio .bar-chart .eje-x li::before {
  content: attr(data-total);
  position: absolute;
  background: #4fc4f6;
  height: var(--height);
  bottom: 1.1rem;
  width: 70%;
  right: 5%;
  box-shadow: 3px 0 rgba(0, 0, 0, 0.1), 3px -3px rgba(0, 0, 0, 0.1);
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  text-align: center;
}

.view-inicio .loading .bar-chart .eje-x li::before {
  animation: grafico-animado 1000ms ease-in;
}

.view-inicio .bar-chart .eje-x li i {
  transform: rotatez(-45deg) translatex(-1rem);
  transform-origin: 30% -40%;
  display: block;
}

@keyframes grafico-animado {
  0% {
    height: 0%;
  }
  100% {
    height: var(--height);
  }
}

@media (max-width: 875px) {
  .view-inicio .grid-container {
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: minmax(200px, 200px, auto);
    grid-template-areas:
      'ingresos servicios'
      'reporteria reporteria'
      'top top';
  }
}

@media (max-width: 576px) {
  .view-inicio .grid-container {
    grid-template-columns: 1fr;
    grid-auto-rows: minmax(200px, 200px, 200px, auto);
    grid-template-areas:
      'ingresos'
      'servicios'
      'reporteria'
      'top';
  }
}
