.op-file-upload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border: 1px solid #cccccc;
  border-radius: 2px;
}

.op-file-upload .title {
  position: absolute;
  top: -12px;
  background-color: #ffffff;
  padding: 0 2px;
  left: 8px;
}

.op-file-upload .op-content-files {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: 10px;
}

.op-file-upload .total {
  font-size: 11px;
  color: red;
}

.op-boton-upload {
  position: relative;
  background-color: transparent;
  border: 1px solid #777;
  border-radius: 5px;
  text-align: left;
  display: flex;
  justify-content: space-around;
  padding: 10px 15px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.op-boton-upload .icon {
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;
  background-position: center;
}

.op-boton-upload .delete {
  cursor: pointer;
  margin-left: 10px;
  z-index: 1;
}

.op-boton-upload input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
}

.op-boton-upload .size {
  position: absolute;
  bottom: 0;
  font-size: 10px;
}
