.op-view-cajero .op-datos-cierre {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 5px;
  width: 100%;
}

.op-view-cajero .op-datos-cierre .op-form-group {
  margin-bottom: 5px;
}
