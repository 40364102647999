.op-wizard {
  width: 100%;
  overflow: hidden;
  display: grid;
  grid-template-rows: 110px 1fr 50px;
  grid-template-areas:
    'header'
    'content'
    'buttons';
}

.op-wizard__header {
  grid-area: header;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.op-wizard__header li {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  position: relative;
}

.op-wizard__header li div.icon {
  height: 16px;
  width: 16px;
  margin-top: 5px;
  z-index: 10;
}

.op-wizard__header li div.icon .image {
  color: #ffffff;
  background: #ccc;
  border-radius: 50%;
  padding: 1px;
  z-index: 10;
  -webkit-transition: background-color 100ms ease-in 500ms;
  -moz-transition: background-color 100ms ease-in 500ms;
  -o-transition: background-color 100ms ease-in 500ms;
  transition: background-color 100ms ease-out 500ms;
}

.op-wizard__header li div.icon .shadow {
  color: #ffffff;
  background: #ccc;
  border-radius: 50%;
  height: 25px;
  width: 0;
  position: absolute;
  top: 55px;
  z-index: -1;
  -webkit-transition: width 100ms ease-in 500ms;
  -moz-transition: width 100ms ease-in 500ms;
  -o-transition: width 100ms ease-in 500ms;
  transition: width 100ms ease-in 500ms;
}

.op-wizard__header li div.icon::before,
.op-wizard__header li div.icon::after {
  content: '';
  background: #ccc;
  height: 5px;
  width: 200px;
  display: block;
  position: absolute;
  left: 0;
  top: 65px;
  z-index: -1;
}

.op-wizard__header li div.icon::after {
  width: 0;
  transition: all 500ms ease-in-out;
}
.op-wizard__header li.active div.icon .image {
  background: transparent;
}

.op-wizard__header li.current div.icon::after {
  background: #148e14;
  width: 200px;
}

.op-wizard__header li.current div.icon .image {
  background: #148e14;
}

.op-wizard__header li.active div.icon .shadow {
  background: #01ab97;
  width: 25px;
}

.op-wizard__header li.active div.icon::after {
  background-color: #01ab97;
  width: 200px;
}

.op-wizard__header li:first-child div.icon::before,
.op-wizard__header li:first-child div.icon::after {
  width: 100px;
  left: 100px;
}

.op-wizard__header li:last-child div.icon::before,
.op-wizard__header li:last-child div.icon::after {
  width: 100px;
}

.op-wizard__header li label {
  color: #01ab97;
  font-weight: bolder;
  margin-top: 15px;
}

.op-wizard__content {
  grid-area: content;
  padding: 1rem 0;
  background: #ffffff;
}

.op-wizard__buttons {
  display: grid;
  grid-area: buttons;
  grid-template-columns: 150px 150px;
  grid-template-areas: 'left right';
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  padding-bottom: 1rem;
}

.op-wizard__buttons-left {
  grid-area: left;
  float: left;
}

.op-wizard__buttons-left:disabled,
.op-wizard__buttons-right:disabled {
  background: #333;
  color: red;
  pointer-events: none;
  cursor: default;
}

.op-wizard__buttons-right {
  grid-area: right;
  float: right;
}

@media (max-width: 875px) {
  .op-wizard__header li {
    width: 100px;
  }

  .op-wizard__header li div.icon::before,
  .op-wizard__header li div.icon::after {
    width: 100px;
  }

  .op-wizard__header li.active div.icon::after,
  .op-wizard__header li.current div.icon::after {
    width: 100px;
  }

  .op-wizard__header li:first-child div.icon::before,
  .op-wizard__header li:first-child div.icon::after {
    width: 50px;
    left: 50px;
  }

  .op-wizard__header li:last-child div.icon::before,
  .op-wizard__header li:last-child div.icon::after {
    width: 50px;
  }
}
