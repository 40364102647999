.op-content-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 800;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: modal-animado 700ms ease-in;
  overflow-x: hidden;
  overflow-y: auto;
}

.op-content-modal .op-modal {
  margin: auto 100px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0 5px 10px #0d0d0d;
  padding: 10px;
  border-radius: 5px;
  flex-basis: 100%;
  max-width: 90%;
  position: relative;
}

.op-content-modal .op-modal.op-small {
  flex-basis: 30%;
}

.op-content-modal .op-modal.op-medium {
  flex-basis: 60%;
}

.op-content-modal .op-modal.op-big {
  flex-basis: 80%;
}

.op-content-modal .op-modal.op-large {
  max-width: 95%;
  margin: 10px;
}

.op-content-modal .op-modal .op-modal-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #3498db;
}

.op-content-modal .op-modal.content-imagen .op-modal-header {
  justify-content: flex-end;
  border-bottom: none;
}

.op-content-modal .op-modal .op-modal-header span {
  font-size: 30px;
  color: #3498db;
  animation: titulo-animado 800ms ease-in;
}

.op-content-modal .op-modal .op-modal-header .op-modal-close {
  height: 24px;
  width: 24px;
  cursor: pointer;
  pointer-events: auto;
}

.op-content-modal .op-modal .op-modal-header .op-modal-close::after,
.op-content-modal .op-modal .op-modal-header .op-modal-close::before {
  content: '';
  position: relative;
  display: block;
  width: 100%;
  height: 4px;
  background-color: #999999;
  cursor: pointer;
}

.op-content-modal .op-modal .op-modal-header .op-modal-close::after {
  transform: rotate(45deg) translateX(12px);
  animation: close-animado 800ms ease-in;
  right: 7px;
}

.op-content-modal .op-modal .op-modal-header .op-modal-close::before {
  transform: rotate(-45deg) translateX(-16px);
  animation: close-animado-second 1000ms ease-in;
  left: 12px;
}

.op-content-modal .op-modal .op-modal-header .op-modal-close.disabled {
  pointer-events: none;
}

.op-content-modal .op-modal.content-imagen .op-modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  padding-top: 0;
}

.op-content-modal .op-modal .op-modal-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid #3498db;
  padding: 5px 0;
  gap: 10px;
  flex-wrap: wrap;
}

.op-content-modal .op-modal .op-modal-footer .op-boton:last-child {
  margin-right: 0;
}

@keyframes close-animado {
  0% {
    transform: rotate(45deg) translateX(0);
  }
  100% {
    transform: rotate(45deg) translateX(12px);
  }
}

@keyframes close-animado-second {
  0% {
    transform: rotate(-45deg) translateX(0);
    opacity: 0;
    left: 0;
  }
  100% {
    transform: rotate(-45deg) translateX(-16px);
    opacity: 1;
    left: 12;
  }
}

@keyframes modal-animado {
  0% {
    top: -50px;
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    top: 0;
  }
}

@keyframes titulo-animado {
  0% {
    transform: translateX(100px);
  }
  100% {
    transform: translateX(0);
  }
}

@media (max-width: 875px) {
  .op-content-modal .op-modal {
    margin: auto 50px;
  }

  .op-content-modal .op-modal.op-small {
    flex-basis: 50%;
  }

  .op-content-modal .op-modal.op-medium {
    flex-basis: 50%;
  }
}

@media (max-width: 576px) {
  .op-content-modal .op-modal {
    margin: auto 10px;
  }

  .op-content-modal .op-modal.op-small {
    flex-basis: 70%;
  }

  .op-content-modal .op-modal.op-medium {
    flex-basis: 80%;
  }
}
