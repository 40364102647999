.op-modal.op-calendar {
  flex-basis: 504px;
  color: #ffffff;
}
.op-content-calendar {
  display: flex;
  position: relative;
}

.op-content-calendar.inline {
  justify-content: center;
  align-items: flex-start;
  color: #ffffff;
  background-color: #222831;
}

.op-content-calendar.mini {
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 80px;
  box-shadow: 0 1px 0 #bdbdbd, 0 2px 0 #fff, 0 3px 0 #bdbdbd, 0 4px 0 #fff,
    0 5px 0 #bdbdbd, 0 0 0 1px #bdbdbd;
  border-radius: 0.6em;
}

.op-content-calendar.mini .content-mini-month {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #ffffff;
  background-color: #fa565a;
  height: 20px;
  width: 100%;
  font-size: 9px;
  text-align: center;
  box-shadow: 0 2px 0 #fa565a;
  border-radius: 0.6em 0.6em 0 0;
}

.op-content-calendar.mini .content-mini-date {
  position: relative;
  background-color: #ffffff;
  height: 35px;
  width: 100%;
  text-align: center;
  font-size: 30px;
  color: #4c566b;
}

.op-content-calendar.mini .content-mini-day,
.op-content-calendar.mini .content-mini-time {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10px;
  width: 100%;
  font-size: 9px;
  font-weight: bolder;
  color: #fa565a;
  background-color: #ffffff;
  border-radius: 0 0 0.6em 0.6em;
}

.op-content-calendar.mini .content-mini-day.time {
  border-radius: 0;
}

.op-content-calendar.mini .content-mini-day div,
.op-content-calendar.mini .content-mini-month div {
  position: absolute;
  width: 100%;
  text-align: center;
}

.op-content-calendar .content-months,
.op-content-calendar .content-main,
.op-content-calendar .content-events {
  display: flex;
  flex-direction: column;
}

.op-content-calendar .content-months {
  background-color: #222831;
}

.op-content-calendar.inline .content-months {
  flex-basis: 155px;
}

.op-content-calendar .content-months .months {
  display: flex;
  flex-direction: column;
  padding: 0 1px;
}

.op-content-calendar .content-months .months > div {
  padding: 3px 30px;
  cursor: pointer;
}

.op-content-calendar .content-months .months > div.selected {
  background-color: #00adb5a1;
}

.op-content-calendar .content-main {
  max-width: 362px;
  background-color: #393e46;
  position: relative;
}

.op-content-calendar.inline .content-main {
  flex-basis: calc(100% - 475px);
  max-width: none;
}

.op-content-calendar.inline.week .content-main {
  flex-basis: 100%;
  display: grid;
  grid-template-columns: 150px 1fr;
  grid-template-areas: 'months months' 'weeks hours';
  max-height: 386px;
}

.op-content-calendar .content-main .calendar-icon,
.op-content-calendar .content-main .calendar-icon.event,
.op-content-calendar .content-events .calendar-icon.event-close {
  position: absolute;
  display: none;
}

.op-content-calendar.inline.week .content-main .calendar-icon {
  display: block;
  top: 10px;
  left: 10px;
  z-index: 1;
  cursor: pointer;
}

.op-content-calendar.inline.week .content-main .calendar-icon.event {
  display: flex;
  column-gap: 10px;
  right: 5px;
  left: auto;
}

.op-content-calendar .content-month,
.op-content-calendar .content-year {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 50px;
  font-size: 30px;
}

.op-content-calendar .content-month {
  color: #00adb5;
  font-weight: 600;
  display: flex;
  position: relative;
}

.op-content-calendar.inline.week .content-month {
  grid-area: months;
}

.op-content-calendar .content-month div {
  width: 350px;
  text-align: center;
  position: absolute;
  animation-duration: 0.5s;
}

.op-content-calendar .content-month div.off,
.op-content-calendar .content-events .content-day div.off,
.op-content-calendar.inline.week
  .content-main
  .content-hours
  .content-day
  div.off,
.op-content-calendar.mini .content-mini-day div.off,
.op-content-calendar.mini .content-mini-month div.off {
  opacity: 0;
  animation-timing-function: ease-out;
  animation-name: month-loader-off;
}

.op-content-calendar .content-month div.on,
.op-content-calendar .content-events .content-day div.on,
.op-content-calendar.inline.week
  .content-main
  .content-hours
  .content-day
  div.on,
.op-content-calendar.mini .content-mini-day div.on,
.op-content-calendar.mini .content-mini-month div.on {
  opacity: 1;
  animation-timing-function: ease-in;
  animation-name: month-loader-on;
}

.op-content-calendar .content-week {
  display: flex;
  flex-direction: row;
  max-height: 336px;
  overflow-y: auto;
}

.op-content-calendar .content-days {
  display: flex;
  flex-wrap: wrap;
  max-width: 350px;
}

.op-content-calendar.inline .content-week,
.op-content-calendar.inline .content-days {
  max-width: none;
}

.op-content-calendar.inline.week .content-week {
  grid-area: weeks;
  flex-direction: column;
  border-top: 1px solid #ffffff;
}

.op-content-calendar.inline.week .content-times {
  grid-area: times;
  z-index: 5;
  background-color: transparent;
}
.op-content-calendar.inline.week .content-hours {
  grid-area: hours;
  padding-left: 10px;
  border-top: 1px solid #ffffff;
  max-height: 336px;
  overflow-y: auto;
  background-color: #222831;
}

.op-content-calendar .content-week > div,
.op-content-calendar .content-days .day {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
}

.op-content-calendar.inline .content-week > div,
.op-content-calendar.inline .content-days .day {
  width: calc(100% / 7);
}

.op-content-calendar.inline.week .content-week > div {
  flex-direction: column;
  width: 130px;
  height: 60px;
  padding: 5px 0;
  border-bottom-width: 1px;
  border-bottom-color: #808080;
  border-bottom-style: solid;
}

.op-content-calendar.inline.week .content-week .day-week {
  cursor: pointer;
}

.op-content-calendar.inline.week .content-week .day-week span:nth-of-type(1) {
  font-size: 12px;
}

.op-content-calendar.inline.week .content-week .day-week span:nth-of-type(2) {
  padding: 5px;
}

.op-content-calendar.inline.week
  .content-week
  .day
  input:checked
  ~ label.day-week
  span:nth-of-type(1) {
  color: #00adb5;
}

.op-content-calendar.inline.week
  .content-week
  .day
  input:checked
  ~ label.day-week
  span:nth-of-type(2) {
  border-radius: 50%;
  background-color: #00adb5;
}

.op-content-calendar.inline .content-days .day {
  height: 70px;
}

.op-content-calendar .content-days .day label:not(.enabled) {
  color: #6c6a6adb;
}

.op-content-calendar .content-days .day label.enabled {
  font-weight: 400;
  cursor: pointer;
}

.op-content-calendar .content-days .day input:checked ~ label.label-day,
.op-content-calendar .content-days .day input:checked ~ label.label-task {
  border: 2px solid #ffffff;
  padding: 6px;
  color: #00adb5;
}

.op-content-calendar .content-days .day label.label-task {
  width: calc(100% - 15px);
  text-align: center;
}

.op-content-calendar.inline.week
  .content-main
  .content-week
  .day
  .content-tasks,
.op-content-calendar .content-days .day label.label-task .content-tasks {
  display: flex;
  justify-content: center;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.op-content-calendar.inline.week
  .content-main
  .content-week
  .day
  .content-tasks
  .task,
.op-content-calendar .content-days .day label.label-task .content-tasks .task {
  width: 8px;
  height: 3px;
  margin-right: 3px;
}

.op-content-calendar
  .content-days
  .day
  label.label-task
  .content-tasks
  .task
  span:last-child {
  width: 50px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.op-content-calendar
  .content-days
  .day
  input:not(:checked)
  ~ label.enabled:hover {
  background-color: rgba(255, 255, 255, 0.12);
  padding: 9px;
}

.op-content-calendar .content-week .day input,
.op-content-calendar .content-days .day input {
  display: none;
}

.op-content-calendar.inline.week .content-hours > div.day-hour {
  position: relative;
  background-color: #393e46;
}

.op-content-calendar.inline.week .content-hours div.day-hour-event,
.op-content-calendar.inline > .content-events > .events div.day-hour-event {
  display: flex;
  flex-direction: column;
  background-color: #4c566b;
  font-size: 12px;
  padding: 5px 2px;
  border-left: 2px solid #00adb5;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  margin-bottom: 3px;
  margin-top: 2px;
}

.op-content-calendar.inline.week
  .content-hours
  > div.day-hour
  div.day-hour-event
  > div {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.op-content-calendar .content-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px 0;
  border-top: 1px solid #027e84;
}

.op-content-calendar .content-footer button {
  background-color: #00adb5a1;
  color: #ffffff;
}

.op-content-calendar .content-events {
  flex-basis: 320px;
  height: 100%;
  background-color: #222831;
}

.op-content-calendar.inline.week .content-main .content-hours .content-day,
.op-content-calendar .content-events .content-day {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 50px;
  font-size: 20px;
  color: #00adb5;
  padding-left: 10px;
}

.op-content-calendar.inline.week .content-main .content-hours .content-day div,
.op-content-calendar .content-events .content-day div {
  position: absolute;
  animation-duration: 0.5s;
}

.op-content-calendar .content-events .events {
  padding-left: 10px;
  position: relative;
}

.op-content-calendar .content-events .events .task {
  display: grid;
  grid-template-columns: 3px 40px 1fr 13px;
  column-gap: 5px;
  cursor: pointer;
  padding: 0 5px;
}

.op-content-calendar .content-events .events .task span.anulado {
  text-decoration: line-through;
}

.op-content-calendar .content-events .events .task span.completo::after,
.op-content-calendar .content-events .events .task span.completo::before {
  content: '';
  position: relative;
  display: block;
  width: 100%;
  height: 2px;
  background-color: green;
}

.op-content-calendar .content-events .events .task span.completo::after {
  transform: rotate(45deg) translateX(15px);
  animation: close-animado 800ms ease-in;
  right: 10px;
  width: 3px;
}

.op-content-calendar .content-events .events .task span.completo::before {
  transform: rotate(-45deg) translateX(-15px);
  animation: close-animado-second 1000ms ease-in;
  left: 12px;
}

.op-content-calendar .content-events .events .task:hover,
.op-content-calendar .content-events .events .task.selected {
  background-color: rgba(255, 255, 255, 0.12);
}

.op-content-calendar .content-events .content-task {
  position: absolute;
  right: 330px;
  top: 20px;
  width: 400px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  padding: 0 10px;
  background-color: #ffffff;
  color: #222831;
}

.op-content-calendar .content-events .content-task::after {
  content: '';
  position: absolute;
  right: -10px;
  top: calc(50% - 10px);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #ffffff;
}

.op-content-calendar .content-events .content-task .close {
  position: absolute;
  right: 5px;
  top: 5px;
  height: 24px;
  width: 24px;
  cursor: pointer;
  pointer-events: auto;
}

.op-content-calendar .content-events .content-task .close::after,
.op-content-calendar .content-events .content-task .close::before {
  content: '';
  position: relative;
  display: block;
  width: 100%;
  height: 4px;
  background-color: #999999;
  cursor: pointer;
}

.op-content-calendar .content-events .content-task .close::after {
  transform: rotate(45deg) translateX(12px);
  animation: close-animado 800ms ease-in;
  right: 7px;
}

.op-content-calendar .content-events .content-task .close::before {
  transform: rotate(-45deg) translateX(-16px);
  animation: close-animado-second 1000ms ease-in;
  left: 12px;
}

.op-content-calendar .content-events .content-task > label {
  align-self: center;
  font-size: 20px;
  color: #00adb5;
  padding: 5px 0;
  width: 100%;
  text-align: center;
  margin-bottom: 5px;
}

.op-content-calendar .content-events .content-task div.info {
  display: grid;
  grid-template-columns: 25px 1fr;
  column-gap: 5px;
}

.op-content-calendar .content-events .content-task div.separador {
  width: 100%;
  height: 1px;
  background-color: #393e46;
}

@keyframes month-loader-on {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes month-loader-off {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(100px);
    opacity: 0;
  }
}

@media only screen and (max-width: 885px) {
  .op-content-calendar .content-main .calendar-icon.event,
  .op-content-calendar .content-events .calendar-icon.event-close {
    display: block;
    top: 10px;
    right: 5px;
    left: auto;
    z-index: 1;
    cursor: pointer;
  }

  .op-content-calendar .content-events {
    position: absolute;
    width: 200px;
    right: -100%;
    z-index: 2;
    opacity: 0;
    transition: all ease-in-out 0.5s;
  }

  .op-content-calendar.openEvent .content-events {
    right: 0;
    opacity: 1;
  }

  .op-content-calendar .content-events .content-task {
    right: 210px;
  }

  .op-content-calendar.inline .content-main {
    flex-basis: calc(100% - 155px);
  }
}

@media only screen and (max-width: 576px) {
  .op-modal.op-calendar {
    flex-basis: 350px;
  }

  .op-content-calendar .content-months {
    position: absolute;
    left: -100%;
    z-index: 2;
    opacity: 0;
    transition: all ease-in-out 0.5s;
  }

  .op-content-calendar.on .content-months {
    left: 0;
    opacity: 1;
  }

  .op-content-calendar .content-main .calendar-icon {
    display: block;
    top: 10px;
    left: 10px;
    z-index: 1;
  }

  .op-content-calendar.inline .content-main {
    flex-basis: 100%;
  }

  .op-content-calendar .content-events .content-task {
    right: 20px;
    width: 300px;
  }

  .op-content-calendar.inline .content-week > div {
    font-size: 13px;
  }
}
