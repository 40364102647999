.op-container-steps {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.op-container-steps ul {
  flex-basis: 100%;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.op-container-steps ul li {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  position: relative;
}

.op-container-steps ul li div.icon {
  height: 16px;
  width: 16px;
  margin-top: 5px;
  z-index: 10;
}

.op-container-steps ul li div.icon .image {
  color: #ffffff;
  background: #ccc;
  border-radius: 50%;
  padding: 1px;
  z-index: 10;
  -webkit-transition: background-color 300ms ease-in 500ms;
  -moz-transition: background-color 300ms ease-in 500ms;
  -o-transition: background-color 300ms ease-in 500ms;
  transition: background-color 300ms ease-out 500ms;
}

.op-container-steps ul li div.icon .shadow {
  color: #ffffff;
  background: #ccc;
  border-radius: 50%;
  height: 25px;
  width: 0;
  position: absolute;
  top: 55px;
  z-index: -1;
  -webkit-transition: width 300ms ease-in 500ms;
  -moz-transition: width 300ms ease-in 500ms;
  -o-transition: width 300ms ease-in 500ms;
  transition: width 300ms ease-in 500ms;
}

.op-container-steps ul li div.icon::before,
.op-container-steps ul li div.icon::after {
  content: '';
  background: #ccc;
  height: 5px;
  width: 200px;
  display: block;
  position: absolute;
  left: 0;
  top: 65px;
  z-index: -1;
}

.op-container-steps ul li div.icon::after {
  width: 0;
  transition: all 300ms ease-in-out;
}
.op-container-steps ul li.active div.icon .image {
  background: transparent;
}
.op-container-steps ul li.active div.icon .shadow {
  background: #148e14;
  width: 25px;
}

.op-container-steps ul li.active div.icon::after {
  background-color: #148e14;
  width: 200px;
}

.op-container-steps ul li:first-child div.icon::before,
.op-container-steps ul li:first-child div.icon::after {
  width: 100px;
  left: 100px;
}

.op-container-steps ul li:last-child div.icon::before,
.op-container-steps ul li:last-child div.icon::after {
  width: 100px;
}

.op-container-steps ul li label {
  color: #01ab97;
  font-weight: bolder;
  margin-top: 15px;
}

.op-container-steps .op-step-content {
  position: relative;
  display: block;
  min-height: 300px;
}

.op-container-steps .op-step-content .op-step-panel {
  position: absolute;
  transition: all 0.3s ease-in-out;
  left: 100%;
  width: 100%;
  opacity: 0;
  padding: 10px 0 0 0;
}

.op-container-steps .op-step-content .op-step-panel.on {
  left: 0;
  opacity: 1;
  animation: step-animado 1000ms ease-in-out;
}

.op-container-steps .op-step-content .op-step-panel.finish {
  left: -100%;
  opacity: 0;
  transition-timing-function: ease-out;
  transition-duration: 1000ms;
}

.op-content-preliminar {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 20px;
  width: 100%;
}

.op-content-preliminar .grupo {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
}

.op-content-preliminar .grupo label {
  grid-column: 1 / 3;
  grid-row: 1;
  text-transform: uppercase;
  background-color: cadetblue;
  color: #ffffff;
  padding: 10px 0;
}

.op-content-preliminar .grupo .cuadro {
  grid-column: 2 / 3;
  grid-row: 1 / 3;
  justify-self: end;
  align-self: center;
  border: 1px solid #000007;
  padding: 10px 60px;
}

.op-content-preliminar .grupo .adjunto {
  grid-column: 1 / 3;
  display: grid;
  grid-template-columns: 40px 25px auto;
}

@keyframes step-animado {
  0% {
    left: 100%;
  }
  100% {
    left: 0;
  }
}
