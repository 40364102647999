.op-boton {
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 3px;
  border: 1px solid #424242;
  height: 30px;
  line-height: 20px;
  cursor: pointer;
  background-color: #ffffff;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.op-boton > i {
  font-size: 15px;
}

.op-boton.op-grabar {
  color: #1ab394;
  border-color: #1ab394;
}

.op-boton.op-grabar:hover,
.op-boton.op-grabar:focus {
  background-color: #1ab394;
  color: #ffffff;
}

.op-boton.op-cerrar {
  color: orange;
  border-color: orange;
}

.op-boton.op-cerrar:hover,
.op-boton.op-cerrar:focus {
  background-color: orange;
  color: #ffffff;
}

.op-boton.op-filter {
  color: #ffffff;
  border-color: #424242;
  background-color: #494949;
}

.op-boton.op-filter:hover,
.op-boton.op-filter:focus {
  border-color: #ffffff;
  color: #ffffff;
}

.op-boton.op-dinamic {
  color: var(--button-color);
  border-color: var(--button-color);
}

.op-boton.op-dinamic:hover,
.op-boton.op-dinamic:focus {
  color: #ffffff;
  background-color: var(--button-color);
}

.op-boton.op-dinamic:hover .op-icon-global,
.op-boton.op-dinamic:focus .op-icon-global {
  background-color: #ffffff !important;
}

.op-boton:disabled,
.op-boton:disabled:hover,
.op-boton:disabled:focus {
  border-color: #999999;
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

.op-boton .op-loading-mini {
  background-color: #ffffff;
  -webkit-mask: url('../../assets/loader-mini.svg') no-repeat center;
  mask: url('../../assets/loader-mini.svg') no-repeat center;
  width: 25px;
  height: 25px;
  position: absolute;
  left: 40%;
  top: 2px;
}

.op-boton .op-dropdown-content {
  position: absolute;
  top: -100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 5px 10px;
  width: calc(100% - 20px);
  z-index: -1;
  opacity: 0;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  transition-property: opacity, top;
  transition-duration: 0.2s, 0.5s;
}

.op-boton .op-dropdown-content.open {
  top: 32px;
  z-index: 10;
  opacity: 1;
}

.op-boton .op-dropdown-content.open.top {
  top: auto;
  bottom: 32px;
}

.op-boton .op-dropdown-content .op-dropdown-item {
  display: grid;
  grid-template-columns: auto auto auto;
  justify-items: start;
  align-items: center;
  color: #424242;
}

.op-boton .op-dropdown-content .op-dropdown-item .op-icon-global {
  background-color: var(--button-icon-color) !important;
}
