.view-pedido .cantidad {
  display: flex;
}

.view-pedido .cantidad span {
  padding: 2px 5px;
  padding-right: none;
  border-radius: 5px;
  display: block;
}

.view-pedido .cantidad.edit span {
  display: none;
}

.view-pedido .cantidad input {
  display: none;
  padding: 2px;
  width: 30px;
}

.view-pedido .cantidad.edit input {
  display: block;
}

.view-pedido .cantidad .edicion {
  background-color: #cccccc;
  padding: 2px 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
  display: none;
}

.view-pedido .cantidad:hover span {
  border: 1px solid #cccccc;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.view-pedido .cantidad:hover .edicion,
.view-pedido .cantidad.edit .edicion {
  display: block;
}

.view-pedido .op-pestania {
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
  height: 50px;
}

.view-pedido .op-pestania span {
  flex-basis: 50%;
  text-transform: uppercase;
  cursor: pointer;
  padding: 5px;
  background-color: antiquewhite;
  border-radius: 3px;
  text-align: center;
}

.view-pedido .op-pestania span.activo {
  background-color: #4a6b8a;
  color: #ffffff;
}

.view-pedido .op-venta-item {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 1px solid #d3d3d3;
  margin-bottom: 5px;
  cursor: pointer;
  position: relative;
}

.view-pedido .op-venta-item .op-item,
.view-pedido .op-venta-item .op-total {
  font-weight: 600;
}

.view-pedido .op-venta-item .op-item {
  flex-basis: 75%;
}

.view-pedido .op-venta-item .op-total {
  flex-basis: 25%;
  align-self: flex-start;
  text-align: right;
}

.view-pedido .op-venta-item .op-descripcion {
  flex-basis: 100%;
  font-size: 14px;
}

.view-pedido .op-venta-item .op-descripcion.descuento {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.view-pedido .op-venta-item .op-descripcion.descuento span:first-child {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.view-pedido .op-venta-item .op-descripcion span:last-child {
  color: #afafaf;
}

.view-pedido .op-venta-item .op-descripcion.descuento span:last-child {
  color: red;
}

.view-pedido .op-notificacion {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
}

.view-pedido .op-notificacion span {
  position: relative;
  color: #1ab394;
  font-size: 12px;
  align-self: flex-end;
  left: -2px;
}

.view-pedido-map .content-map {
  display: grid;
  grid-template-rows: 50px auto;
  width: 100%;
}

.view-pedido-map .content-map .op-bloque {
  z-index: 9;
}

.view-pedido-map .content-map .op-form {
  padding: 15px 10px;
}
