.op-table {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.op-table .op-search {
  display: flex;
  justify-content: flex-end;
}

.op-table .op-search > input {
  padding: 10px;
  font-family: 'Arial';
  margin-bottom: 5px;
}

.op-table .op-search i.op-icon-search {
  margin: 5px;
  cursor: pointer;
  background: transparent;
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: center;
  width: 24px;
  height: 20px;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
}

.op-table table {
  color: #000000;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid #ebebeb;
  border-collapse: collapse;
}

.op-table table thead tr th,
.op-table table tbody tr td {
  border: 1px solid #e7e7e7;
  padding: 7px;
  line-height: 14px;
  border-collapse: collapse;
  transition-property: height, font-size, padding, opacity;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
}

.op-table table tbody tr td > i {
  cursor: pointer;
}

.op-table table tbody tr td .op-table-ocultar {
  display: none;
  color: #94c12e;
  margin-right: 5px;
  position: relative;
  bottom: 0;
  height: 20px;
  width: 15px;
}

.op-table table tbody tr td .op-table-ocultar::before {
  content: '';
  height: 4px;
  width: 16px;
  background-color: #94c12e;
  display: block;
  transition: 0.5s ease-in-out;
  position: relative;
  top: 6px;
  opacity: 1;
}

.op-table table tbody tr td .op-table-ocultar::after {
  content: '';
  display: block;
  position: absolute;
  bottom: 10px;
  height: 4px;
  width: 16px;
  background-color: #94c12e;
  transition: 0.5s ease-in-out;
  transform: rotate(90deg);
}

.op-table table tbody tr.off td .op-table-ocultar::after {
  opacity: 0;
}

.op-table table tbody tr.off td .op-table-ocultar::before {
  background-color: red;
}

.op-table table thead tr {
  color: #ffffff;
  background-color: #9c9c9c;
}

.op-table table thead tr:not(th:last-child) {
  border-right: 2px solid #ffffff;
}

.op-table table tbody tr:nth-child(2n + 0) {
  background-color: #e4ebf8;
}

.op-table table tbody.op-hover tr.op-seleccionado,
.op-table table tbody.op-hover tr:hover {
  background-color: #3498db;
  color: #ffffff;
}

.op-table table tbody.op-hover tr:hover span {
  color: #ffffff !important;
}

.op-table table tbody tr.op-vacio,
.op-table table tbody tr.op-procesando {
  text-align: center;
}

.op-table .op-pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.op-table .op-pagination ul {
  display: flex;
  list-style: none;
  margin: 0;
  margin-top: 3px;
}

.op-table .op-pagination ul li {
  padding: 4px 10px;
  background-color: #d3d3d3;
  color: #ffffff;
  cursor: pointer;
}

.op-table .op-pagination ul li.op-prev {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.op-table .op-pagination ul li.op-next {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.op-table .op-pagination ul li.op-prev::after {
  content: 'Anterior';
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.op-table .op-pagination ul li.op-next::after {
  content: 'Siguiente';
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.op-table .op-pagination ul li.op-prev:not(.disabled):hover,
.op-table .op-pagination ul li.op-next:not(.disabled):hover,
.op-table .op-pagination ul li.op-pagina:hover {
  background-color: #3498db;
}

.op-table .op-pagination ul li.op-prev.disabled,
.op-table .op-pagination ul li.op-next.disabled {
  cursor: not-allowed;
  pointer-events: none;
}

.op-table .op-pagination ul li.op-pagina {
  border-left: 1px solid #ffffff;
}

.op-table .op-pagination ul li.op-pagina:nth-last-child(-n + 2) {
  border-right: 1px solid #ffffff;
}

.op-table .op-pagination ul li.op-pagina.op-current {
  background-color: #3498db;
}

.op-totales {
  display: grid;
  grid-template-columns: auto 150px;
  justify-items: end;
  margin-top: 10px;
}

.op-totales .separador {
  grid-column: 1 / 3;
  height: 1px;
  width: 35%;
  margin: 3px 0;
  background-color: #3498db;
}

@media (max-width: 875px) {
  .op-totales .separador {
    width: 55%;
  }
}

@media only screen and (max-width: 576px) {
  .op-table {
    padding: 20px 10px;
  }

  .op-table .op-search {
    justify-content: center;
  }

  .op-table .op-search > input {
    flex-grow: 2;
  }

  .op-table .op-pagination {
    flex-direction: column;
    align-items: center;
  }

  .op-table table thead tr th.sm-hide {
    display: none;
  }

  .op-table table tbody tr td:nth-child(n + 2) {
    display: block;
    width: auto;
    border: none;
    border-bottom: 1px solid #e7e7e7;
  }

  .op-table table tbody tr td.sm-hide {
    padding: 0;
    height: 0;
    font-size: 0;
    border: none;
    opacity: 0;
    visibility: hidden;
  }

  .op-table table tbody tr.off td.sm-hide {
    padding: 7px;
    font-size: 16px;
    line-height: 14px;
    border-collapse: collapse;
    height: auto;
    opacity: 1;
    visibility: visible;
    text-align: left;
  }

  .op-table table tbody tr.off td:last-child {
    border-bottom: 1px solid #e7e7e7;
  }

  .op-table table tbody tr:last-child td:nth-last-child(1),
  .op-table table tbody tr:not(.off):last-child td:nth-child(3) {
    border-bottom: none;
  }

  .op-table table tbody tr td .op-table-ocultar {
    display: inline-block;
  }

  .op-table table tbody tr td:nth-child(n + 3)::before {
    content: attr(data-label);
  }

  .op-table .op-pagination ul {
    padding-left: 0;
  }

  .op-table .op-pagination ul li.op-prev::after {
    content: 'Ant.';
  }

  .op-table .op-pagination ul li.op-next::after {
    content: 'Sig.';
  }

  .op-totales .separador {
    width: 100%;
  }
}

@media only screen and (max-width: 370px) {
  .op-table .op-pagination ul li.op-prev::after {
    content: '<';
  }

  .op-table .op-pagination ul li.op-next::after {
    content: '>';
  }
}
