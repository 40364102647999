.op-app .op-content-menu {
  position: fixed;
  left: 0;
  top: 0;
  width: 60px;
  height: 100%;
  transition: 0.5s;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #e9e9e9;
  z-index: 700;
}

.op-app .op-content-menu:hover,
.op-app .op-content-menu.active {
  width: 300px;
}

.op-app .op-content-menu ul {
  width: 100%;
  padding: 0;
  margin: 0;
}

.op-app .op-content-menu ul li {
  position: relative;
  width: 100%;
  list-style: none;
}

.op-app .op-content-menu ul li a {
  position: relative;
  display: block;
  width: 100%;
  display: flex;
  text-decoration: none;
  color: #494949;
  background-color: #e9e9e9;
}

.op-app .op-content-menu ul li a .icon {
  position: relative;
  display: flex;
  min-width: 60px;
  height: 60px;
  justify-content: center;
  align-items: center;
}

.op-app .op-content-menu ul li a .icon .dropdown {
  transform: rotate(-90deg);
  transition: 0.5s;
}

.op-app .op-content-menu ul li.activo > a .icon .dropdown {
  transform: rotate(0deg);
}

.op-app .op-content-menu ul li a .title {
  position: relative;
  flex-grow: 1;
  display: block;
  padding: 0 10px;
  height: 60px;
  line-height: 60px;
  text-align: start;
  white-space: nowrap;
}

.op-app .op-content-menu ul.submenu {
  display: none;
}

.op-app .op-content-menu li.activo > ul.submenu {
  display: block;
}

.op-app .op-content-menu li.activo > a::before {
  content: '';
  position: absolute;
  width: 14px;
  height: 14px;
  background-color: #e9e9e9;
  left: 20px;
  bottom: -7px;
  transform: rotate(45deg);
  z-index: 1;
}

.op-app .op-content-menu ul.submenu.even li.activo > a::before {
  background-color: #424242;
}

.op-app .op-content-menu ul.submenu.odd li.activo > a::before {
  background-color: #b7b7b7;
}

.op-app .op-content-menu ul.submenu.even > li a {
  background-color: #424242;
  color: #e9e9e9;
}

.op-app .op-content-menu ul.submenu.odd > li a {
  color: #494949;
  background-color: #b7b7b7;
}

.op-app .op-content-menu ul li a:hover,
.op-app .op-content-menu li.activo > a:hover::before,
.op-app .op-content-menu ul.submenu li > a:hover::before {
  background-color: #1a95d5 !important;
  color: #ffffff !important;
}

@media (max-width: 767px) {
  .op-app .op-content-menu {
    left: -60px;
    top: 40px;
    height: calc(100% - 40px);
  }

  .op-app .op-content-menu.active {
    left: 0;
    width: 100%;
  }
}
