.op-content-login {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}

.op-content-login .op-login {
  flex-basis: 30%;
  border: 1px solid #cccccc;
  border-radius: 10px;
  padding: 50px 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.op-content-login .op-login h1 {
  align-self: center;
}

.op-content-login .op-login h5 {
  align-self: center;
  color: #999999;
}

.op-content-login .op-login button {
  margin: 0;
}

.op-content-login .op-login .op-error {
  color: red;
}

.op-content-login .op-login .op-form-group.error {
  margin-bottom: 20px;
}

.op-content-login .op-login .op-olvido {
  margin-top: 10px;
  align-self: center;
  color: blue;
  text-decoration: none;
  cursor: pointer;
}

.op-content-login .op-login .op-button-link {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
  padding: 10px;
  text-decoration: none;
  margin-bottom: 15px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
}

.op-content-login .op-login .op-button-link span {
  color: #383838;
}

.op-content-login .op-login .op-otro {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
  text-decoration: none;
  margin-top: 15px;
}

.op-content-login .op-login .op-captcha {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.op-content-login .op-login .op-captcha > div {
  width: 100%;
  height: 50px;
  background-color: #cccccc;
}

@media (max-width: 768px) {
  .op-content-login .op-login {
    flex-basis: 60%;
  }
}

@media (max-width: 576px) {
  .op-content-login .op-login {
    flex-basis: 80%;
  }
}
