.op-view-equipo .op-content-preconfig {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    column-gap: 5px;
    background-color: #d7d7d7;
}

.op-view-equipo .op-content-preconfig .op-preconfig {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #ffffff;
    padding:  0 10px;
}

.op-view-equipo .op-content-preconfig .op-preconfig h2 {
    text-align: center;
    flex-basis: 100%;
    display: block;
}

.op-view-equipo .op-content-preconfig .op-preconfig .op-preconfig-item {
    position: relative;
    display: grid;
    grid-template-columns: 25px 1fr;
    grid-template-rows: 1fr 1fr;
    column-gap: 10px;
    background-color: #eff2f3;
    border-radius: 8px;
    padding: 5px 20px;
    box-shadow: 1px 1px 5px #ccc;
    cursor: pointer;
    margin-right: 10px;
    margin-bottom: 27px;
    align-self: flex-start;
}

.op-view-equipo .op-content-preconfig .op-preconfig .op-preconfig-item .close {
    position: absolute;
    top: -25px;
    right: 0px;
}

.op-view-equipo .op-content-preconfig .op-preconfig .op-preconfig-item .op-icon-global {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    justify-self: center;
    align-self: center;
}

.op-view-equipo .op-content-preconfig .op-preconfig .op-preconfig-item span:nth-of-type(1) {
    font-size: 11px;
    color: #939393;
    align-self: end;
}

@media (max-width: 875px) {
    .op-view-equipo .op-content-preconfig {
        grid-template-columns: 1fr;
        row-gap: 5px;
    }

    .op-view-equipo .op-content-preconfig .op-preconfig {
        padding: 10px;
    }
}