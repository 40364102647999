.op-view-solicitud {
  column-gap: 10px;
}

.op-view-solicitud .half:first-child:nth-last-child(n + 3),
.op-view-solicitud .half:first-child:nth-last-child(n + 3) ~ .half {
  flex-basis: calc(50% - 5px);
  width: calc(50% - 5px);
}

.op-view-solicitud .half.inactive {
  display: none;
}

.op-view-solicitud .op-bloque.maintenance {
  transition: transform 400ms ease-out;
  transform: translate3d(100%, 0, 0);
}

.op-view-solicitud .op-bloque.maintenance.active {
  transform: translate3d(0, 0, 0);
}

.op-view-solicitud .op-bloque.maintenance .request-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'request equipment'
    'request images'
    'request manuals';
}

.op-view-solicitud .op-detail-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: auto;
  column-gap: 10px;
  row-gap: 5px;
  flex-basis: 100%;
  justify-items: center;
}

.op-view-solicitud .op-detail-container .op-detail-icon {
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 8px;
  background-color: #fdfdfd;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 20px;
  padding-top: 5px;
  box-shadow: 1px 1px 5px #ccc;
  cursor: pointer;
}

.op-view-solicitud .op-detail-container .op-detail-icon:nth-of-type(2) {
  background-color: #fcebe3;
}

.op-view-solicitud .op-detail-container .op-detail-icon:nth-of-type(3) {
  background-color: #e6f2fe;
}

.op-view-solicitud .op-detail-container .op-detail-icon:nth-of-type(4) {
  background-color: #ecfae1;
}

.op-view-solicitud .op-detail-container .op-detail-icon span {
  font-size: 11px;
  align-self: flex-end;
}

.op-view-solicitud .op-detail-container .op-detail-icon::before {
  bottom: 0;
  content: '';
  height: 4px;
  left: 45%;
  position: absolute;
  transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  -webkit-transition: all 0.6s ease;
  width: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.op-view-solicitud .op-detail-container .op-detail-icon.active::before {
  background: #3498db;
  left: 0;
  width: 100%;
}

.op-view-solicitud .op-detail-container .op-detail-main {
  grid-column: 1 / 6;
  width: 100%;
  padding-top: 10px;
}

.op-view-solicitud .op-detail-container .op-detail-main .detail-info {
  display: grid;
  grid-template-columns: 200px 1fr;
  column-gap: 10px;
}

.op-view-solicitud
  .op-detail-container
  .op-detail-main
  .detail-info
  span:first-child {
  justify-self: end;
}

.op-view-solicitud
  .op-detail-container
  .op-detail-main
  .detail-info
  span:last-child {
  font-weight: bolder;
}

.op-view-solicitud
  .op-detail-container
  .op-detail-main
  .detail-info
  > span
  > span {
  font-size: 0;
  display: block;
}

.op-view-solicitud
  .op-detail-container
  .op-detail-main
  .detail-equipments
  .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.op-view-solicitud
  .op-detail-container
  .op-detail-main
  .detail-equipments
  .group {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.op-view-solicitud
  .op-detail-container
  .op-detail-main
  .detail-equipments
  .group
  .lineStatus {
  position: absolute;
  height: 60px;
  border-left-width: 3px;
  border-left-color: #808080;
  border-left-style: solid;
  left: 44px;
  top: -30px;
}

.op-view-solicitud
  .op-detail-container
  .op-detail-main
  .detail-equipments
  .container {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0;
}

.op-view-solicitud
  .op-detail-container
  .op-detail-main
  .detail-equipments
  .container
  .equipment {
  position: relative;
  display: grid;
  grid-template-columns: 25px 1fr;
  grid-template-rows: 1fr 1fr;
  column-gap: 5px;
  background-color: #eff2f3;
  border-radius: 8px;
  padding: 5px 20px;
  box-shadow: 1px 1px 5px #ccc;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 27px;
}

.op-view-solicitud
  .op-detail-container
  .op-detail-main
  .detail-equipments
  .container
  .equipment
  .close {
  position: absolute;
  top: -25px;
  right: 0px;
}

.op-view-solicitud
  .op-detail-container
  .op-detail-main
  .detail-equipments
  .container
  .equipment.active {
  border: 1px solid green;
}

.op-view-solicitud
  .op-detail-container
  .op-detail-main
  .detail-equipments
  .container
  .equipment
  .op-icon-global {
  grid-column: 1 / 2;
  grid-row: 1 / 3;
  justify-self: center;
  align-self: center;
}

.op-view-solicitud
  .op-detail-container
  .op-detail-main
  .detail-equipments
  .container
  .equipment
  span:nth-of-type(1) {
  font-size: 11px;
  color: #939393;
  align-self: end;
}

.op-view-solicitud
  .op-detail-container
  .op-detail-main
  .detail-equipments
  .container
  .op-boton {
  width: 100%;
  margin-top: 10px;
}

.op-view-solicitud .op-detail-container .op-detail-main > .op-detail-component {
  display: none;
}

.op-view-solicitud
  .op-detail-container
  .op-detail-main
  > .op-detail-component.active {
  display: block;
}

.op-view-solicitud
  .op-detail-container
  .op-detail-main
  > .op-detail-component
  .op-detail-contenedor {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.op-view-solicitud
  .op-detail-container
  .op-detail-main
  > .op-detail-component
  .op-detail-contenedor
  .op-detail-result {
  display: grid;
  grid-template-columns: 300px 1fr;
  align-items: center;
  margin-bottom: 10px;
}

.op-view-solicitud .cantidad {
  display: flex;
}

.op-view-solicitud .cantidad span {
  padding: 2px 5px;
  padding-right: none;
  border-radius: 5px;
  display: block;
}

.op-view-solicitud .cantidad.edit span {
  display: none;
}

.op-view-solicitud .cantidad input {
  display: none;
  padding: 2px;
  width: 30px;
}

.op-view-solicitud .cantidad.edit input {
  display: block;
}

.op-view-solicitud .cantidad .edicion {
  background-color: #cccccc;
  padding: 2px 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
  display: none;
}

.op-view-solicitud .cantidad:hover span {
  border: 1px solid #cccccc;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.op-view-solicitud .cantidad:hover .edicion,
.op-view-solicitud .cantidad.edit .edicion {
  display: block;
}

@media (max-width: 875px) {
  .op-view-solicitud .half {
    flex-basis: 100% !important;
    width: 100% !important;
  }

  .op-view-solicitud .half.list {
    order: 2;
  }

  .op-view-solicitud .op-content-filtro {
    order: 3;
  }

  .op-view-solicitud .op-detail-icon {
    padding: 5px !important;
  }

  .op-view-solicitud .op-detail-icon > span {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .op-view-solicitud .op-detail-icon .op-icon-global {
    width: 25px !important;
    height: 25px !important;
  }

  .op-view-solicitud .op-detail-container .op-detail-main .detail-info,
  .op-view-solicitud
    .op-detail-container
    .op-detail-main
    > .op-detail-component
    .op-detail-contenedor
    .op-detail-result {
    grid-template-columns: 60% 40%;
  }
}
