.op-content-forgot {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
}

.op-content-forgot .op-content {
  padding: 50px 100px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 70px;
}

.op-content-forgot .titulo {
  font-size: 55px;
  font-weight: bold;
  margin-bottom: 50px;
}

.op-content-forgot .op-forgot {
  flex-basis: 30%;
  border: 1px solid #cccccc;
  border-radius: 10px;
  padding: 10px 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.op-content-forgot .op-forgot h1 {
  text-align: center;
}

.op-content-forgot .op-forgot h3,
.op-content-forgot .op-forgot h5 {
  color: #999999;
  text-align: center;
}

.op-content-forgot .op-forgot .op-captcha {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.op-content-forgot .op-forgot .op-captcha > div {
  width: 100%;
  height: 50px;
  background-color: #cccccc;
}

.op-content-forgot .op-forgot .op-error {
  color: red;
}

.op-content-forgot .op-forgot .op-form-group.error {
  margin-bottom: 20px;
}

.op-content-forgot .op-forgot .iniciar {
  text-decoration: none;
  margin-top: 10px;
}

@media (max-width: 881px) {
  .op-content-forgot .op-content {
    padding: 50px;
    gap: 10px;
  }

  .op-content-forgot .titulo {
    font-size: 45px;
  }
}

@media (max-width: 665px) {
  .op-content-forgot .op-content {
    justify-content: flex-start;
    flex-direction: column;
  }

  .op-content-forgot .titulo {
    margin-bottom: 0;
    text-align: center;
  }
}
