.view-ordencompra .cantidad {
  display: flex;
}

.view-ordencompra .cantidad span {
  padding: 2px 5px;
  padding-right: none;
  border-radius: 5px;
  display: block;
}

.view-ordencompra .cantidad.edit span {
  display: none;
}

.view-ordencompra .cantidad input {
  display: none;
  padding: 2px;
  width: 30px;
}

.view-ordencompra .cantidad.edit input {
  display: block;
}

.view-ordencompra .cantidad .edicion {
  background-color: #cccccc;
  padding: 2px 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
  display: none;
}

.view-ordencompra .cantidad:hover span {
  border: 1px solid #cccccc;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.view-ordencompra .cantidad:hover .edicion,
.view-ordencompra .cantidad.edit .edicion {
  display: block;
}
