.op-app .op-header {
  position: absolute;
  top: 0;
  left: 60px;
  width: calc(100% - 60px);
  height: 40px;
  background-color: #494949;
  display: flex;
  justify-content: space-between;
  box-shadow: 2px -2px 9px #888888;
  transition: 0.5s;
}

.op-app .op-header.active {
  left: 300px;
  width: calc(100% - 300px);
}

.op-app .op-header .op-header-app {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 5px;
  flex-grow: 1;
  padding-left: 5px;
  overflow: hidden;
}

.op-app .op-header .op-header-app .op-header-item {
  align-self: flex-end;
  border: 1px solid #949494e2;
  border-bottom: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  align-items: center;
}

.op-app .op-header .op-header-app .op-header-item label {
  padding: 5px 15px 1px 5px;
  color: #949494e2;
  cursor: pointer;
  white-space: nowrap;
}

.op-app .op-header .op-header-app .op-header-item.selected {
  border-color: orange;
  border-width: 2px;
}

.op-app .op-header .op-header-app .op-header-item.selected label {
  color: #ffffff;
}

.op-app .op-header .op-header-actions {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
  padding: 0 5px;
}

.op-app .op-header .op-header-actions .listado {
  display: block;
}

.op-app .op-header .op-header-actions .op-bars input {
  display: none;
}

.op-app .op-header .op-header-actions .op-bars label {
  font-size: 20px;
  color: #ffffff;
  cursor: pointer;
}

.op-app .op-header .op-header-actions .op-bars label span {
  height: 5px;
  width: 30px;
  background-color: #ffffff;
  display: block;
  margin: 5px 0;
  transition: 0.7s ease-in-out;
  transform: none;
}

.op-app
  .op-header
  .op-header-actions
  .op-bars
  input:checked
  ~ label
  span:nth-of-type(3) {
  opacity: 0;
}

.op-app
  .op-header
  .op-header-actions
  .op-bars
  input:checked
  ~ label
  span:nth-of-type(1) {
  transform: translate(0%, 175%) rotate(-45deg);
}

.op-app
  .op-header
  .op-header-actions
  .op-bars
  input:checked
  ~ label
  span:nth-of-type(2) {
  transform: rotate(45deg);
}

@media (max-width: 767px) {
  .op-app .op-header,
  .op-app .op-header.active {
    left: 0;
    width: 100%;
    justify-content: flex-end;
  }

  .op-app .op-header::before {
    content: 'WUASHO';
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 0 5px;
    font-size: 20px;
    font-weight: bold;
    color: #999;
  }

  .op-app .op-header .op-header-app {
    display: none;
  }

  .op-app .op-header .op-header-actions .listado {
    display: block;
  }
}
