.op-content-checkbox {
  width: 100px;
}

.op-content-checkbox.disabled {
  pointer-events: none;
}

.op-content-checkbox .op-checkbox-toogle {
  display: block;
  position: relative;
  height: 42px;
  cursor: pointer;
}

.op-content-checkbox .op-checkbox-toogle .op-checkbox-cheker::before,
.op-content-checkbox .op-checkbox-toogle .op-checkbox-cheker::after {
  content: '';
  display: block;
  position: absolute;
  width: 14px;
  height: 2px;
  margin: 0 auto;
  top: 20px;
  left: 0;
  right: 0;
  background-color: #bf1e1e;
  border-radius: 5px;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.op-content-checkbox .op-checkbox-toogle .op-checkbox-cheker::before {
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}

.op-content-checkbox .op-checkbox-toogle .op-checkbox-cheker::after {
  -webkit-transform: rotateZ(-45deg);
  transform: rotateZ(-45deg);
}

.op-content-checkbox .op-checkbox-toogle > input {
  display: none;
}

.op-content-checkbox
  .op-checkbox-toogle
  > input:checked
  + .op-checkbox-cheker::before,
.op-content-checkbox
  .op-checkbox-toogle
  > input:checked
  + .op-checkbox-cheker::after {
  background-color: #0cb018;
}

.op-content-checkbox
  .op-checkbox-toogle
  > input:checked
  + .op-checkbox-cheker::before {
  width: 6px;
  top: 23px;
  left: -7px;
}

.op-content-checkbox
  .op-checkbox-toogle
  > input:checked
  + .op-checkbox-cheker::after {
  width: 12px;
  left: 5px;
}

.op-content-checkbox .op-checkbox-toogle > input:checked + .op-checkbox-cheker {
  left: calc(100% - 43px);
  -webkit-transform: rotateZ(360deg);
  transform: rotateZ(360deg);
}

.op-content-checkbox .op-checkbox-toogle .op-checkbox-cheker {
  display: block;
  position: absolute;
  height: 43px;
  width: 43px;
  top: -1px;
  left: 0px;
  border-radius: 50%;
  background-color: #ffffff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  z-index: 2;
}

.op-content-checkbox .op-checkbox-toogle .op-checkbox-on,
.op-content-checkbox .op-checkbox-toogle .op-checkbox-off {
  display: block;
  position: absolute;
  width: 42px;
  top: 15px;
  text-align: center;
  color: #0cb018;
  font-size: 12px;
  z-index: 1;
}

.op-content-checkbox .op-checkbox-toogle .op-checkbox-off {
  right: 0;
  color: #bf1e1e;
}

.op-content-checkbox .op-checkbox-toogle .op-checkbox-svg {
  position: absolute;
  top: 0;
  left: 0;
  fill: #aaaaaa;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: center;
  background-image: url("data:image/svg+xml;utf8,<svg class='checkbox__bg' space='preserve' style='enable-background:new 0 0 110 43.76;' version='1.1' viewbox='0 0 110 43.76'><path class='shape' d='M88.256,43.76c12.188,0,21.88-9.796,21.88-21.88S100.247,0,88.256,0c-15.745,0-20.67,12.281-33.257,12.281,S38.16,0,21.731,0C9.622,0-0.149,9.796-0.149,21.88s9.672,21.88,21.88,21.88c17.519,0,20.67-13.384,33.263-13.384,S72.784,43.76,88.256,43.76z'></path></svg>");
}

.op-content-checkbox .op-checkbox-toogle:focus {
  outline: 2px ridge #3498db;
  outline-offset: 4px;
}
