.op-promocion-container {
  position: relative;
  width: 500px;
  height: 350px;
}

.op-promocion-container .actions {
  position: relative;
  display: flex;
  justify-content: flex-end;
  height: 30px;
  top: 0;
  right: 0;
}

.op-promocion-container .actions .icon {
  font-size: 25px;
  cursor: pointer;
}

.op-loading-mini {
  background-color: #858585;
  -webkit-mask: url('../../assets/loader-mini.svg') no-repeat center;
  mask: url('../../assets/loader-mini.svg') no-repeat center;
  width: 25px;
  height: 25px;
  position: relative;
  left: 40%;
  top: 2px;
}
