.op-content-informativo {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 20px 50px;
}

.op-content-informativo .op-informativo {
  background-color: #ffffff;
  border: 1px solid #fafafa;
  padding: 10px 20px;
  box-shadow: 3px 3px 5px #888888;
  border-radius: 5px;
}

.op-content-informativo .op-informativo .titulo {
  color: #4a6b8a;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
}

.op-content-informativo .op-informativo .op-datos {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 5px;
}

.op-content-informativo .op-informativo .op-datos span {
  justify-self: end;
}
