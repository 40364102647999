@import url('https://fonts.googleapis.com/css?family=Open+Sans');

body {
  padding: 0;
  margin: 0;
  font-family: 'Open Sans';
}

.op-app {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.op-centrar-componentes {
  display: flex;
  justify-content: center;
  align-items: center;
}

.op-content-tipomoneda {
  position: relative;
  height: 20px;
  width: 100px;
  border: 1px solid #467326;
  color: #467326;
  border-radius: 3px;
}

.op-content-tipomoneda > div {
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  animation-duration: 0.5s;
}

.op-content-tipomoneda > div.on {
  animation-name: tipomoneda-on;
  animation-timing-function: ease-in;
  opacity: 1;
}

.op-content-tipomoneda > div.off {
  animation-name: tipomoneda-off;
  animation-timing-function: ease-in;
  opacity: 0;
  z-index: -1;
}

.op-arrow-down::before {
  content: '';
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #f00;
  margin-right: 3px;
  position: relative;
  top: -2px;
}

.op-arrow-up::before {
  content: '';
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid green;
  margin-right: 3px;
  position: relative;
  top: -5px;
}

.op-error {
  color: red;
}

.op-delete {
  color: red;
  text-decoration: line-through;
}

.op-warning {
  color: orange;
}

.op-success {
  color: green;
}

@keyframes tipomoneda-on {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
