.view-cambio {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px 300px;
}

.view-cambio .op-form-group {
  margin-bottom: 25px;
}

@media (max-width: 867px) {
  .view-cambio {
    margin: 20px 100px;
  }
}

@media (max-width: 576px) {
  .view-cambio {
    margin: 10px 40px;
  }
}
