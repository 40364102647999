.op-content-filtro {
  position: relative;
  flex-basis: 100%;
  align-self: flex-end;
}

.op-content-filtro .op-filtro {
  background-color: #494949;
  color: #ffffff !important;
  min-height: 15px;
  padding: 5px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.op-content-filtro .op-filtro .op-icono-filtro::after {
  content: 'FILTRAR';
}

.op-content-filtro .op-filtro .op-actions {
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
}

.op-content-filtro .op-contenido {
  position: absolute;
  bottom: 50px;
  width: 100%;
  left: -100%;
  transition: all 1s;
  opacity: 0;
  visibility: hidden;
  z-index: 500;
}

.op-content-filtro .op-contenido > .op-form {
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
}

.op-content-filtro.op-close .op-contenido {
  left: 0;
  opacity: 1;
  visibility: visible;
}

.op-content-filtro .op-filtro .up {
  transform: rotate(0);
  transition: transform 0.5s;
}

.op-content-filtro.op-close .op-filtro .up {
  transform: rotate(90deg);
}

@media only screen and (max-width: 576px) {
  .op-content-filtro .op-filtro .op-icono-filtro::after {
    content: '';
  }
}
